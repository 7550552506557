<template>
        <!-- Start Blog Area  -->
        <v-col lg="5"
                     md="5"
                     sm="5"
                     cols="12">
                  <div class="single-column">
                      
                        <div v-for="(gift, i) in gifts"
                              :key="i">
                              <img :src="`${gift.image}`" />
                              <p> {{ gift   .title }} </p>
                              <p> {{ gift.points }} πόντοι</p>
                              <p> {{ gift.description }} </p>
                          </div>
                      
                  </div>
              </v-col>
        <!-- End Blog Area  -->
</template>

<script>

import { mapMutations, mapActions, mapGetters } from "vuex";


    export default {  
        name: 'gifts',
        computed: {
            ...mapGetters (["gifts"]),
        },
         async  created () {
            await this.getGifts(8);
        },
        methods: {
            ...mapActions(["getGifts"]),
        },
    }    


</script>