<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->
    <!-- Start Column Area  -->
    <div class="rn-column-area rn-section-gap mt--150">
      <v-container>
        <center>
          <h3 class="mb--100"><b>Τα νέα μας</b></h3>
        </center>

        <div v-if="notificationArray.length == 0">
          Δεν υπάρχουν δεδομένα
        </div>
        <div v-else>
          <v-row
            id="userNotificationsList"
            v-for="(column, i) in notificationArray"
            :key="i"
            class="mb--100 blog_details"
          >
            <v-col v-if="column" lg="6" md="6" sm="12" xs="12" cols="12" align="right">

              <div
                class="single-column d-flex justify-content-md-end justify-content-xs-center"
              >
                <img
                  v-if="column.image||column.content&&column.content.image"
                  style="max-width: 400px"
                  :src="`${column.image||column.content&&column.content.image}`"
                />
                <img
                  v-else
                  style="max-width: 400px"
                  src="@/assets/images/service/test.png"
                />
              </div>
            </v-col>
            <v-col lg="6" md="6" sm="12" xs="12" cols="12" align="left">
              <div
                class="single-column mt--10 ml--15  justify-content-md-start justify-content-xs-center"
              >
                <h3 v-if="column.content"> {{ column.content.title.el }}</h3>
                <h6 v-if="column.content"> {{ column.content.body.el }}</h6>
                <p>{{ moment(column.created_at) }}</p>
                <!-- <p v-if="isJson(column.content)"> {{ column.content.el }}</p> -->
                <p v-if="column.content"> {{ column.content.el }}</p>
                <p v-else> {{ column.content }}</p>
                <!-- <p v-if="column.image">{{ column.content }}</p>

                <p v-else>testset</p> -->
              </div>
            </v-col>
          </v-row>
          <b-pagination
            class="mt--100"
            v-if="this.userNotifications.pagination.total_pages > 1"
            v-model="currentPage"
            :per-page="this.userNotifications.pagination.items_per_page"
            :total-rows="this.userNotifications.pagination.total"
            v-on:click.native="scrollToTop"
            aria-controls="userNotificationsList"
            align="center"
          ></b-pagination>
        </div>
      </v-container>
    </div>
    <!-- End Column Area  -->

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/Header";
import Footer from "@/components/footer/FooterTwo";
import Gifts from "@/components/gifts/Gifts";
import axios from "axios";
import { mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  components: {
    Header,
    Footer,
    Gifts,
    moment,
  },
  data() {
    return {
      currentPage: 1,
      loading: true,
    };
  },
  watch: {
    currentPage: async function(newQuestion, oldQuestion) {
      await this.getUserNotifications(newQuestion);
    },
  },
  computed: {
    ...mapGetters(["userNotifications"]),
    ...mapGetters(["user"]),
    notificationArray(){
      return (this.userNotifications.user_notifications||[]).filter(e => !!e.content)||[]
    }
  },
  async created() {
    if (!this.user) {
      this.$loading(true);
      await this.getUser();
      this.$loading(false);
      if (!this.user) {
        this.$router.push("/");
        return;
      }
    }
    if (!this.userNotifications) {
      this.$loading(true);
      await this.getUserNotifications(1);
      this.$loading(false);
    } else {
      await this.getUserNotifications(1);
    }
  },
  methods: {
    ...mapActions(["getUserNotifications"]),
    ...mapActions(["getUser"]),
    scrollToTop() {
      this.$vuetify.goTo(0);
    },
    moment: function(created_at) {
      return moment(String(created_at)).format("DD-MM-YYYY, hh:mm");
    },
    isJson: function(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.blog_details {
  padding: 30px 0 20px 10px;
  box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3);
}
</style>
